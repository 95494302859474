/*
* @Author: Jeff Shields
* @Date:   2019-07-22 08:32:53
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-07-22 10:25:51
*/
.navbar-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1029;
}

.bg-custom{
    background-color: transparent;
    background-color: rgba(0, 78, 153, .2);
}
