/* !main
* @Author: Jeff Shields
* @Date:   2019-01-14 08:53:09
* @Last Modified by:   Jeff Shields
* @Last Modified time: 2019-07-22 08:57:03
*/

// ===========================================================
@import "project/setup";


// ===========================================================
//                    Load themes
// ===========================================================
@import "bs4themes/bootswatch/dist/darkly/variables";

// Required
@import "project/bs4-required";

// Your variable overrides go here, e.g.:
@import "mythemes/johnston2/variables";

@import "project/load-bs4";

@import "bs4themes/bootswatch/dist/darkly/bootswatch";
@import "mythemes/johnston2/_custom";
